export const createLightning = (
  originX: number,
  originY: number,
  length: number,
  maxDiff: number,
) => {
  const roughness = 1.2;
  const minSegmentHeight = 50;
  let segmentHeight = length;
  let lightning: { x: number, y: number }[] = [];
  lightning.push({ x: originX, y: 0 });
  lightning.push({ x: originX, y: originY });

  let currDiff = maxDiff;

  while (segmentHeight > minSegmentHeight) {
    const newSegments: { x: number, y: number }[] = [];
    for (let i = 0; i < lightning.length - 1; i++) {
      const start = lightning[i];
      const end = lightning[i + 1];

      const midX = (start.x + end.x) / 2;
      const newX = midX + (Math.random() * 2 - 1) * currDiff;

      newSegments.push(start, {
        x: newX,
        y: (start.y + end.y) / 2,
      });
    }

    newSegments.push(lightning.pop()!);
    lightning = newSegments;
    currDiff /= roughness;
    segmentHeight /= 2;
  }

  return lightning;
}
