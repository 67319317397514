import Noise from './Noise';

export default class Mountain {
  step: number;
  scale: number;
  speed: number;
  noise: Noise;
  start: number;
  yOffset: number;
  fillStyle: string;
  strokeStyle: string;
  points: [number, number][] = [];

  constructor (step: number, scale: number, speed: number) {
    this.step = step;
    this.scale = scale;
    this.speed = speed;
    this.noise = new Noise('', 0.0005);
    this.start = 0;
    this.yOffset = 0;
    this.fillStyle = '#fff';
    this.strokeStyle = '#fff';
  }

  draw (ctx: CanvasRenderingContext2D) {
    this.start += this.speed;
    this.points = [];

    let startX: number = 0;
    let startY: number = 0;

    ctx.beginPath();

    for (let x = this.start - ctx.canvas.width; x < this.start; x += this.step) {
      const y = this.noise.getVal(x * this.scale) * 100;

      if (x === this.start - ctx.canvas.width) {
        this.points.push([this.start - x, this.yOffset + y]);
        startX = this.start - x;
        startY = this.yOffset + y;
        ctx.moveTo(startX, startY);
      } else {
        const nx = x + this.step;
        const xc = (this.start - x + (this.start - nx)) / 2;
        const ny = this.noise.getVal(nx * this.scale) * 100;
        const yc = (y + ny) / 2;
        this.points.push([this.start - x, this.yOffset + y]);
        ctx.quadraticCurveTo(
          this.start - x,
          this.yOffset + y,
          xc,
          this.yOffset + yc,
        );

        if (x + this.step >= this.start) {
          ctx.lineTo(0, this.yOffset + this.noise.getVal((x + this.step) * this.scale) * 100);
        }
      }
    }

    ctx.lineTo(0, ctx.canvas.height);
    ctx.lineTo(ctx.canvas.width, ctx.canvas.height);
    ctx.lineTo(startX, startY);

    ctx.fillStyle = this.fillStyle;
    ctx.fill();
    ctx.strokeStyle = this.strokeStyle;
    ctx.stroke();
  }
}
