export type Point = [number, number, number];

export default class Sketch {
  points: Point[];
  canvas: HTMLCanvasElement;
  colour: string;
  delay: number;
  variation: number;

  constructor (
    points: Point[],
    canvas: HTMLCanvasElement,
    colour: string,
    delay: number,
    variation = 2,
  ) {
    this.points = points;
    this.canvas = canvas;
    this.colour = colour || 'rgba(0,0,0,0.3)';
    this.delay = delay || 30;
    this.variation = variation;

    this.canvas.width = Math.floor(this.canvas.clientWidth);
    this.canvas.height = Math.floor(this.canvas.clientHeight);
  }

  jitter () {
    const points = JSON.parse(JSON.stringify(this.points));

    let i = points.length;

    while (i--) {
      points[i][0] += -this.variation + Math.random() * this.variation * 2;
      points[i][1] += -this.variation + Math.random() * this.variation * 2;
    }

    return points;
  }

  drawLine (ctx: CanvasRenderingContext2D, p1: Point, p2: Point, d: number) {
    setTimeout(() => {
      ctx.beginPath();
      ctx.moveTo(p1[0], p1[1]);
      ctx.lineTo(p2[0], p2[1]);
      ctx.stroke();
    }, d);
  }

  draw (loop = 1) {
    const ctx = this.canvas.getContext('2d')!;

    for (let iter = 0; iter < loop; iter++) {
      ctx.strokeStyle = this.colour;

      const points = this.jitter();
      const delay = this.delay + (-(this.delay / 2) + Math.random() * this.delay * 2);

      let i = points.length - 1;

      while (i--) {
        if (!points[i + 1][2]) {
          this.drawLine(ctx, points[i + 1], points[i], i * delay);
        }
      }
    }
  }
}
