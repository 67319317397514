import { Point } from './Sketcher';

const sketches: Record<string, Point[]> = {
  'KrAwx': [[22,33,0],[25,46,0],[25,56,0],[25,67,0],[23,76,0],[21,84,0],[21,96,0],[25,105,0],[29,112,0],[34,120,0],[134,118,0],[129,105,0],[124,90,0],[125,77,0],[129,65,0],[129,54,0],[128,40,0],[124,31,0],[22,35,0],[37,36,1],[40,44,0],[41,55,0],[40,66,0],[39,78,0],[37,88,0],[38,100,0],[42,109,0],[48,121,0],[62,120,1],[57,109,0],[53,96,0],[52,82,0],[55,70,0],[57,56,0],[55,44,0],[53,35,0],[68,35,1],[70,45,0],[69,58,0],[67,71,0],[67,80,0],[67,87,0],[69,97,0],[73,106,0],[79,119,0],[94,119,1],[90,108,0],[84,95,0],[81,84,0],[80,70,0],[84,59,0],[84,47,0],[82,34,0],[97,34,1],[99,43,0],[100,55,0],[98,63,0],[97,76,0],[97,89,0],[101,97,0],[104,109,0],[107,119,0],[120,118,1],[115,104,0],[113,96,0],[111,84,0],[111,75,0],[115,64,0],[115,56,0],[115,44,0],[112,34,0],[128,47,1],[26,47,0],[26,64,1],[129,61,0],[125,76,1],[24,78,0],[21,94,1],[124,90,0],[128,105,1],[28,109,0]],
  'YYXavM': [[58,138,0],[60,129,0],[60,117,0],[58,102,0],[50,89,0],[36,80,0],[18,79,0],[28,72,0],[39,74,0],[42,66,0],[31,39,0],[14,39,0],[29,33,0],[31,18,0],[35,29,0],[38,40,0],[51,29,0],[43,45,0],[48,57,0],[51,71,0],[55,74,0],[55,51,0],[56,38,0],[54,23,0],[44,15,0],[58,17,0],[61,27,0],[74,25,0],[64,33,0],[62,45,0],[71,41,0],[81,21,0],[77,42,0],[65,51,0],[62,61,0],[63,72,0],[69,63,0],[76,56,0],[86,50,0],[88,36,0],[90,56,0],[80,60,0],[89,68,0],[77,66,0],[73,71,0],[67,81,0],[67,94,0],[71,109,0],[74,127,0],[81,137,0]],
  'dJgMaO2': [[62,50,0],[72,31,0],[87,27,0],[107,31,0],[114,43,0],[113,58,0],[101,71,0],[83,73,0],[69,78,0],[61,84,0],[59,97,0],[59,105,0],[57,116,0],[48,122,0],[33,124,0],[17,121,0],[32,116,0],[39,109,0],[45,97,0],[45,87,0],[47,73,0],[50,62,0],[58,53,0],[63,48,0]],
  'fhjvk': [[15,138,0],[17,31,0],[45,33,0],[41,139,0],[15,137,0],[42,111,0],[15,110,0],[42,84,0],[17,83,0],[42,59,0],[18,56,0],[44,36,0],[19,33,1],[43,58,0],[16,58,1],[42,84,0],[17,86,1],[41,112,0],[17,113,1],[38,138,0],[45,35,1],[103,36,0],[43,84,0],[103,38,1],[101,79,0],[84,89,0],[94,107,0],[112,96,0],[102,80,0],[94,107,0],[112,97,1],[85,92,0]],
  'LzifE': [[25,56,0],[26,43,0],[34,36,0],[48,31,0],[61,33,0],[74,41,0],[78,51,0],[78,63,0],[72,74,0],[62,81,0],[49,86,0],[36,81,0],[27,74,0],[25,56,0],[84,76,1],[99,72,0],[112,76,0],[121,86,0],[123,99,0],[120,112,0],[113,119,0],[101,121,0],[88,121,0],[78,115,0],[73,102,0],[73,89,0],[85,77,0],[84,62,1],[85,45,0],[91,64,1],[107,62,0],[90,57,1],[101,44,0],[49,93,1],[34,93,0],[60,92,1],[47,109,0],[67,104,1],[67,123,0]],
  'hszvg': [[43,46,0],[61,43,0],[72,49,0],[75,63,0],[86,66,0],[108,57,0],[118,49,0],[127,48,0],[133,54,0],[136,66,0],[132,77,0],[125,82,0],[114,85,0],[109,89,0],[107,97,0],[107,107,0],[105,115,0],[94,121,0],[83,118,0],[80,112,0],[78,98,0],[75,92,0],[71,84,0],[67,81,0],[57,78,0],[50,77,0],[43,76,0],[39,70,0],[36,64,0],[35,52,0],[44,47,0],[50,98,1],[38,97,0],[30,104,0],[31,112,0],[36,119,0],[47,118,0],[52,113,0],[50,100,0]],
  'dJgMaO': [[23,43,0],[27,57,0],[30,74,0],[30,91,0],[28,101,0],[25,118,0],[39,119,0],[62,118,0],[77,118,0],[85,118,0],[102,118,0],[115,121,0],[107,97,0],[108,84,0],[109,70,0],[114,58,0],[118,44,0],[102,40,0],[88,39,0],[74,42,0],[63,44,0],[49,44,0],[40,43,0],[28,42,0],[23,43,0],[117,25,1],[123,29,0],[128,34,0],[130,43,0],[130,52,0],[124,17,1],[131,20,0],[137,29,0],[139,35,0],[139,41,0]],
  'zxJKv': [[40,40,0],[44,42,0],[53,48,1],[57,51,0],[63,61,1],[67,68,0],[73,73,1],[73,64,0],[71,53,1],[70,45,0],[64,40,1],[59,35,0],[69,77,1],[73,79,1],[81,72,0],[83,62,1],[83,54,0],[82,45,1],[79,39,0],[70,86,1],[78,85,0],[86,81,1],[88,77,0],[95,69,1],[96,62,0],[77,93,1],[88,94,0],[96,89,1],[99,85,0],[64,91,1],[68,97,0],[74,104,1],[82,105,0],[92,107,1],[102,107,0],[58,86,1],[55,93,0],[56,103,1],[58,108,0],[66,115,1],[72,118,0],[55,79,1],[44,85,0],[43,93,1],[43,103,0],[58,71,1],[50,68,0],[41,70,1],[34,76,0],[30,82,1],[30,88,0],[52,56,1],[43,54,0],[35,51,1],[29,52,0],[106,80,1],[111,74,0],[101,54,1],[101,46,0]],
};

export default sketches;
