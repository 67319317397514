export default class Noise {
  scale: number;
  MAX_VERTICES: number;
  MAX_VERTICES_MASK: number;
  r: number[];

  constructor (seed: string, scale: number) {
    this.scale = scale || 1;
    this.MAX_VERTICES = 256;
    this.MAX_VERTICES_MASK = this.MAX_VERTICES - 1;
    this.r = [];

    // seed && Math.seedrandom(seed);

    for (let i = 0; i < this.MAX_VERTICES; ++i) {
      this.r.push(Math.random());
    }
  }

  getVal (x: number) {
    const scaledX = x * this.scale;
    const xFloor = Math.floor(scaledX);
    const t = scaledX - xFloor;
    const tRemapSmoothstep = t * t * (3 - 2 * t);

    const xMin = xFloor & this.MAX_VERTICES_MASK;
    const xMax = xMin + 1 & this.MAX_VERTICES_MASK;

    const lerp = function (a: number, b: number, t: number) {
      return a * (1 - t) + b * t;
    };

    const y = lerp(this.r[xMin], this.r[xMax], tRemapSmoothstep);

    return y;
  }
}
