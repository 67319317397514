import { BrowserRouter, Routes, Route, Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

import { throttle, useStateRef } from './util';
import { createLightning } from './Lightning';
import { embedCodepen } from './codepen';
import Mountain from './Mountain';
import sketches from './sketches';
import Sketch from './Sketcher';
import NotFound from './NotFound';

import codepenImg from './image/codepen.svg';
import githubImg from './image/github.svg';
import twitterImg from './image/twitter.svg';
import instagramImg from './image/instagram.svg';
import linkedinImg from './image/linkedin.svg';

interface AppProps {
  page: number;
}

const App = ({ page }: AppProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sketchesRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const mountainCanvas1 = useRef<HTMLCanvasElement>(null);
  const mountainCanvas2 = useRef<HTMLCanvasElement>(null);
  const mountainCanvas3 = useRef<HTMLCanvasElement>(null);
  const mountain1 = useRef(new Mountain(30, 22, -0.1));
  const mountain2 = useRef(new Mountain(10, 10, -0.1));
  const mountain3 = useRef(new Mountain(10, 4, -0.1));
  const canDraw = useRef(true);

  const { penID } = useParams()

  const [ menuPos, setMenuPos ] = useState(0);
  const [ selectedPen, setSelectedPen ] = useState<string | null>(penID || null);

  const navigate = useNavigate();

  const [
    initialState,
    setInitialState,
    initialStateRef,
  ] = useStateRef(page <= 1);

  const [
    hasSketched,
    setHasSketched,
    hasSketchedRef,
  ] = useStateRef(false);

  useEffect(() => {
    const handleScroll = throttle((event: Event) => {
      const { scrollTop, clientHeight } = event.currentTarget as HTMLDivElement;

      const pages = scrollRef.current?.querySelectorAll<HTMLDivElement>('.page') || [];
      const closest = Array.from(pages).reverse().findIndex((page) => {
        const offset = page.offsetTop - scrollTop;
        if (offset < clientHeight / 2) return true;
        return false;
      });

      setMenuPos(pages.length - closest - 1);
    }, 100);

    const onScroll = (event: Event) => {
      const { scrollTop } = event.currentTarget as HTMLDivElement;

      if (initialStateRef.current && scrollTop > 10) {
        setInitialState(false);
      }

      if (!initialStateRef.current && scrollTop < 10) {
        setInitialState(true);
      }

      if (scrollTop <= canvasRef.current!.clientHeight) {
        const mag = scrollTop / canvasRef.current!.clientHeight;
        mountainCanvas1.current!.style.transform = `translateY(${-250 * mag}px)`;
        mountainCanvas2.current!.style.transform = `translateY(${-125 * mag}px)`;
      }

      handleScroll(event);
    };

    if (!hasSketchedRef.current && page === 2) {
      setHasSketched(true);

      const sketchPoints = Object.values(sketches);

      sketchesRef.current?.querySelectorAll('canvas')
        .forEach((canvas, i) => {
          setTimeout(() => {
            if (sketchPoints[i]) {
              const colour = 'rgba(0,0,0,0.2)';
              new Sketch(sketchPoints[i], canvas, colour, 16, 2).draw(2);
            }
          }, i * 200);
        });
    }

    setTimeout(() => {
      scrollRef.current?.addEventListener('scroll', onScroll);
    }, 100);

    return () => {
      scrollRef.current?.removeEventListener('scroll', onScroll);
    };
  }, [page]);

  useEffect(() => {
    if (initialState) {
      setHasSketched(false);
      sketchesRef.current?.querySelectorAll('canvas')
        .forEach((canvas) => {
          const ctx = canvas.getContext('2d')!;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        });
    }
  }, [initialState]);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext('2d')!;
    const color = '#f2f2f2';

    const drawLightning = () => {
      const originI = 5 + ~~(Math.random() * (mountain3.current.points.length - 10));
      const originX = mountain3.current.points[originI][0];
      const originY = mountain3.current.points[originI][1];
      const lightning = createLightning(
        originX,
        originY,
        canvas.height,
        canvas.height / 10,
      );

      let lastPoint = lightning[0];

      lightning.slice(1).forEach((segment, i) => {
        ctx.beginPath();
        ctx.moveTo(lastPoint.x, lastPoint.y);
        ctx.lineTo(segment.x, segment.y);
        lastPoint = segment;
        ctx.strokeStyle = color;
        ctx.lineWidth = 0.3 + (1 - i / lightning.length) * 8;
        ctx.stroke();
      });
    };

    const update = () => {
      ctx.globalCompositeOperation = 'destination-in';
      ctx.fillStyle = `rgba(255, 0, 0, ${1 - 0.2})`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'source-over';

      if (canDraw.current &&
          initialStateRef.current &&
          Math.random() < 0.01
        ) {
        drawLightning();
      }

      window.requestAnimationFrame(update);
    };

    update();
  }, []);

  useEffect(() => {
    canDraw.current = false;

    setTimeout(() => {
      [canvasRef, mountainCanvas1, mountainCanvas2, mountainCanvas3]
        .forEach((canvas) => {
          canvas.current?.classList.add('ready');
          canvas.current!.width = canvas.current!.clientWidth;
          canvas.current!.height = canvas.current!.clientHeight;
        });

      mountain1.current.yOffset = canvasRef.current!.height - 420;
      mountain1.current.fillStyle = '#333';
      mountain1.current.strokeStyle = '#333';
      mountain1.current.draw(mountainCanvas1.current?.getContext('2d')!);

      mountain2.current.yOffset = canvasRef.current!.height - 300;
      mountain2.current.fillStyle = '#888';
      mountain2.current.strokeStyle = '#999';
      mountain2.current.draw(mountainCanvas2.current?.getContext('2d')!);

      mountain3.current.yOffset = canvasRef.current!.height - 200;
      mountain3.current.fillStyle = '#f2f2f2';
      mountain3.current.strokeStyle = '#f2f2f2';
      mountain3.current.draw(mountainCanvas3.current?.getContext('2d')!);

      setTimeout(() => {
        canDraw.current = true;
      }, 500);
    }, 500)
  }, []);

  useEffect(() => {
    const pageEl = scrollRef.current?.querySelector(`.page:nth-child(${page})`);
    pageEl?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [page]);

  useEffect(() => {
    menuRef.current?.querySelectorAll<HTMLAnchorElement>('a')
      .forEach((el, i) => {
        el.classList.toggle('selected', i === menuPos);

        if (canDraw.current && i === menuPos) {
          const url = new URL(el.href);
          navigate(url.pathname);
        }
      });
  }, [menuPos]);

  useEffect(() => {
    if (selectedPen) {
      embedCodepen();
      navigate(`/pen/${selectedPen}`);
    } else {
      !initialState &&
      navigate(`/showcase`);
    }
  }, [selectedPen]);

  return (
    <div className={classNames('site-wrapper', {
      scrolled: !initialState,
    })}>
      <div>
        <div className="menu" ref={menuRef}>
          <Link to="/">home</Link>
          <Link to="/showcase">showcase</Link>
        </div>
        <div className="title">
          Adam Brooks
        </div>
      </div>
      <div ref={scrollRef}>
        <div className="page"></div>
        <div className="page">
          <div
            ref={sketchesRef}
            className="sketches">
            {Object.keys(sketches).map((penID) => (
              <div key={penID}>
                <canvas onClick={() => {
                  setSelectedPen(penID);
                }} />
              </div>
            ))}
          </div>
        </div>
        <canvas ref={mountainCanvas1} className="mountain-canvas" />
        <canvas ref={mountainCanvas2} className="mountain-canvas" />
        <canvas ref={mountainCanvas3} className="mountain-canvas" />
        <canvas ref={canvasRef} className="lightning-canvas" />
        <div className="footer">
          <div className="links">
            <a target="_blank" href="https://codepen.io/dissimulate">
              <img alt="codepen" src={codepenImg} />
            </a>
            <a target="_blank" href="https://www.instagram.com/abro_oks/">
              <img alt="instagram" src={instagramImg} />
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/adam-brooks-12b9a4117/">
              <img alt="linkedin" src={linkedinImg} />
            </a>
            <a target="_blank" href="https://github.com/dissimulate/">
              <img alt="github" src={githubImg} />
            </a>
            <a target="_blank" href="https://twitter.com/abro_oks">
              <img alt="twitter" src={twitterImg} />
            </a>
          </div>
        </div>
      </div>
      {selectedPen &&
        <div className="pen-modal" onClick={() => {
          setSelectedPen(null);
        }}>
          <p
            className="codepen"
            data-height="600"
            data-theme-id="40174"
            data-default-tab="result"
            data-slug-hash={selectedPen}
            data-user="dissimulate">
            Loading...
          </p>
        </div>
      }
    </div>
  );
};

const wrapper = document.getElementById('app');
ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App page={1} />} />
      <Route path="/showcase" element={<App page={2} />} />
      <Route path="/pen/:penID" element={<App page={2} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>,
  wrapper,
);
